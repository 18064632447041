const config = {
  API_URL: '/api',

  ORDER_LIMIT: 20,

  ORDER_POLLING_RATE: 15000,
  ORDER_DETAILS_POLLING_RATE: 30000,

  CHANNELS: {
    EMAIL: 1,
    MOBILE: 2,
    PUSH_NOTIF: 3,
    PRINT: 4
  },

  SENDING_ORDER_STATUS: {
    SCHEDULED: 1,
    SENDING_IN_PROGRESS: 2,
    SENT: 3,
    ERROR: 4,
    PAUSED: 6
  },

  PROCESSING_ORDER_STATUS: {
    READY: "READY",
    END: "END",
    CANCELLED: "CANCELLED",
    CLEANING: "CLEANING"
  },

  RESEND_STATUS: {
    ONGOING: 1,
    ERROR: 2,
    SENT: 3
  },

  // These status are used to update order in Cindy
  ORDER_STATUS: {
    STARTED: 1,
    PAUSED: 2,
    SENT: 3
  },

  ORDER_FETCH_DIRECTION: {
    RECENT: 1,
    OLDER: 2
  },

  ORDER_LIST_TYPE: {
    NO_FILTER: 1,
    SEARCH: 2,
    FILTER: 3,
    CLIENT: 4
  },

  CAMPAIGN_TYPE: {
    ONE_SHOT: 1,
    AUTOMATIC: 2,
    ON_DEMAND: 4
  },

  SWITCH_VIEW:{
    APPS : 'apps',
    CLIENT: 'clients'
  },

  MAX_SENDING_SPEED: 1000000,
  EMAIL_SENDING_SPEED_WARNING: 150000,
  MIN_SENDING_SPEED: 500,
}

export default config
