<template>
  <div class="order-details-modal-body">
    <div
      class="order-details-container"
      data-tested-as="orderDetails"
    >
      <div class="order-details-header pb-1">
        <div class="margin-right-20">
          <el-tooltip
            :content="avatarInfo.name"
            placement="top"
            effect="dark"
          >
            <avatar-holder
              :avatar-url="avatarInfo.logo"
              :display-name="avatarInfo.name"
              :width="32"
              :height="32"
            />
          </el-tooltip>
        </div>
        <div class="order-details-header-container">
          <div class="bigger-title">
            {{ order.campaign_name }}
          </div>
          <div class="tags">
            <div class="align-center">
              <i class="material-icons md-12">
                {{ channelTags[order.channel_id].icon }}
              </i>
              <span class="subtitle">
                {{ channelTags[order.channel_id].title }}
              </span>
            </div>
            <div class="align-center">
              <i class="material-icons md-12">
                {{ typeTags[order.type_id].icon }}
              </i>
              <span class="subtitle">
                {{ typeTags[order.type_id].title }}
              </span>
            </div>
          </div>

          <el-row
            :class="{'pb-2': order.ignore_date}"
          >
            <div class="subtitle">
              <i
                v-if="isInterrupted"
                class="material-icons interrupted"
              >
                hourglass_empty
              </i>
              <el-tag
                :type="statusClass"
                size="small"
                effect="dark"
              >
                {{ $t(status) }}
              </el-tag>
              <span v-if="isError">
                <span v-if="order.ignore_date">
                  &ndash; {{ $t("ORDER_STATUS_CANCELLED") }}
                </span>
              </span>
            </div>
          </el-row>
        </div>
      </div>
      <hr class="solid">
      <div
        v-if="!isLoadingDetails"
        class="pb-4"
      >
        <el-row
          v-if="!order.ignore_date"
          class="pt-1 pb-2"
        >
          <div class="subtitle progress-bar-title">
            {{ $t("PROGRESS") }}
          </div>
          <el-tooltip placement="top">
            <div slot="content">
              {{ order.volumes_sent }} {{ $tc("ORDER_DETAILS_VOLUME_SENT_LABEL", order.volumes_sent) }}
            </div>
            <el-progress
              :text-inside="true"
              :stroke-width="16"
              :percentage="progressValue"
              :color="progressColor"
            />
          </el-tooltip>
        </el-row>
        <el-row
          v-if="canBeUnscheduled"
          class="order-error-help"
        >
          <i class="el-icon-info" />
          <div>
            <span data-tested-as="detailsUnscheduleInformation">
              {{ $t(`CAMPAIGN_DETAIL_INFO_SENDING_DATE_NOT_REACHED`) }}
            </span>
          </div>
        </el-row>

        <el-row
          v-if="isError && canResend && order.error && !resendInProgress"
          class="order-error-description"
        >
          <p>
            <b>{{ failureDate }}</b><br>
            <b v-if="order.processing_step">
              {{ $t('ERROR_STEP') }} {{ order.processing_step }}
            </b>
          </p>
          <br>
          <p>
            {{ order.error.error_description }}
          </p>
        </el-row>
        <el-row
          v-if="isPaused"
          class="order-warning-description"
        >
          <i class="el-icon-warning" />
          <div>
            <span>
              {{ $t('CAMPAIGN_PAUSED_BY', {
                firstname: pausedBy.first_name,
                lastname: pausedBy.last_name,
                date: pauseDate
              }) }}
            </span>
          </div>
        </el-row>
        <el-row
          v-if="isError && !resendInProgress"
          class="order-error-help"
        >
          <i class="el-icon-info" />
          <div v-if="!order.ignore_date">
            <span v-if="sendingDateReached">
              {{ $t(`CAMPAIGN_DETAIL_ERROR_MESSAGE${canResend ? '_RESEND' : ''}`) }}
            </span>
            <span v-else>
              {{ $t(`CAMPAIGN_DETAIL_ERROR_MESSAGE_2${canResend ? '_RESEND' : ''}`) }}
            </span>
          </div>

          <div v-else-if="canResend && order.ignored_by && order.ignored_by.first_name">
            {{ $t("CAMPAIGN_CANCELLED_BY", {
              date: ignoreDate,
              firstname: order.ignored_by.first_name,
              lastname: order.ignored_by.last_name
            }) }}
          </div>
          <div v-else>
            {{ $t("CAMPAIGN_CANCELLED", { date: ignoreDate}) }}
          </div>
        </el-row>
        <el-row
          v-if="order.error && order.error.resent_date && !order.ignore_date"
          class="order-error-help"
        >
          <i class="el-icon-info" />
          <div>
            <span v-if="order.error.resent_by && order.error.resent_by.first_name">
              {{ $t(`CAMPAIGN_RESENT_BY${resendInFailure ? '_FAIL' : ''}`, {
                firstname: order.error.resent_by.first_name,
                lastname: order.error.resent_by.last_name,
                date: resentDate
              })
              }}
            </span>
            <span v-else>
              {{ $t(`CAMPAIGN_RESENT${resendInFailure ? '_FAIL' : ''}`, { date: resentDate }) }}
            </span>
            <span v-if="resendInProgress">
              {{ $t(`RESEND_IN_PROGRESS`) }}
            </span>
          </div>
        </el-row>

        <!-- RESTART A SENDING ERROR OR CANCEL IT -->
        <el-row
          v-if="canCancelSending"
          class="pt-3"
        >
          <el-button
            v-if="sendingDateReached"
            class="order-button"
            size="medium"
            @click="$emit('update-state', 'cancel')"
          >
            <i class="material-icons">highlight_off</i>
            <span>{{ $t("BUTTON_CANCEL_SENDING") }}</span>
          </el-button>
          <el-button
            class="order-button"
            type="primary"
            size="medium"
            @click="$emit('update-state', 'resend')"
          >
            <i class="material-icons">send</i>
            <span>{{ $t("BUTTON_RESEND_SENDING") }}</span>
          </el-button>
        </el-row>

        <!-- RESTART A PAUSED SENDING OR STOP IT (EMAIL, PN) -->
        <el-row
          v-if="isPaused && !isError && order.channel_id !== refChannels.MOBILE"
          class="pt-3"
        >
          <el-button
            class="order-button"
            size="medium"
            @click="$emit('update-state', 'stop')"
          >
            <i class="material-icons">highlight_off</i>
            <span>{{ $t("BUTTON_CANCEL_SENDING") }}</span>
          </el-button>
          <el-button
            class="order-button"
            type="warning"
            size="medium"
            @click="$emit('update-state', 'resume')"
          >
            <i class="material-icons">play_arrow</i>
            <span>{{ $t("BUTTON_RESUME_SENDING") }}</span>
          </el-button>
        </el-row>

        <!-- PAUSE A SENDING (EMAIL, PN) -->
        <el-row
          v-if="canBePaused"
          class="pt-3"
        >
          <el-button
            data-tested-as="detailsPauseButton"
            class="order-button"
            type="primary"
            size="medium"
            @click="$emit('update-state', 'pause')"
          >
            <i class="material-icons">pause</i>
            <span>{{ $t("BUTTON_PAUSE_SENDING") }}</span>
          </el-button>
        </el-row>
      </div>
    </div>
    <div
      v-loading="isLoadingDetails"
      class="order-details-row-title order-details-container pb-3 pt-3"
      style="background-color: #F9FAF9"
    >
      <el-row
        type="flex"
        justify="space-between"
        class="order-details-row order-details-idm"
      >
        <el-col
          :span="6"
          class="text-left"
        >
          {{ $t("IDM_LABEL") }}
        </el-col>
        <el-col
          :span="18"
          class="text-right"
        >
          <display-crm-ids :order="order" />
        </el-col>
      </el-row>
      <el-row
        type="flex"
        justify="space-between"
        class="order-details-row"
      >
        <el-col
          :span="10"
          class="text-left"
        >
          {{ $t('CAMPAIGN_DETAIL_START_DATE') }}:
        </el-col>
        <el-col
          :span="11"
          class="text-right"
        >
          {{ startDate }}
        </el-col>
      </el-row>
      <el-row
        type="flex"
        justify="space-between"
        class="order-details-row"
      >
        <el-col
          class="text-left"
        >
          {{ endDateLabel }}
        </el-col>
        <el-col
          class="text-right"
        >
          {{ endDate }}
        </el-col>
      </el-row>
      <el-row
        v-if="order.volume === null"
        type="flex"
        justify="space-between"
        class="order-details-row"
      >
        <el-col
          :span="16"
          class="text-left"
        >
          {{ volumeTranslation(true) }}:
        </el-col>
        <el-col
          :span="8"
          class="text-right"
        >
          {{ estimatedVolume }}
        </el-col>
      </el-row>

      <el-row
        v-else
        type="flex"
        justify="space-between"
        class="order-details-row"
      >
        <el-col
          :span="16"
          class="text-left"
        >
          {{ volumeTranslation() }}:
        </el-col>
        <el-col
          :span="8"
          class="text-right"
        >
          {{ order.volume }}
        </el-col>
      </el-row>
      <el-row
        v-if="order.volumes_sent !== null"
        type="flex"
        justify="space-between"
        class="order-details-row"
      >
        <el-col
          :span="10"
          class="text-left"
        >
          {{ $t('CAMPAIGN_DETAIL_VOLUME_SENT') }}:
        </el-col>
        <el-col
          :span="10"
          class="text-right"
        >
          {{ order.volumes_sent }}
        </el-col>
      </el-row>
      <el-row
        type="flex"
        justify="space-between"
        style="padding: 10px 0;"
      >
        <el-col
          :span="12"
          class="text-left flex-center"
        >
          {{ $t('CAMPAIGN_DETAIL_SENDING_SPEED') }}:
        </el-col>
        <el-col
          :span="12"
          class="text-right"
        >
          <span
            v-if="isEditSpeedMode"
            style="display: flex; align-items: center;"
            class="edit-sending-speed-span flex-center"
          >
            <el-input
              v-model="newSendingSpeed"
              data-tested-as="editSendingSpeedInputField"
              @input="processSendingSpeedInput"
            />
            <el-button
              data-tested-as="editSendingSpeedSubmitButton"
              type="primary"
              size="small"
              :loading="isLoadingUpdateSendingSpeed"
              @click="submitSendingSpeed"
            >
              <i
                v-if="!isLoadingUpdateSendingSpeed"
                class="material-icons"
                style="font-size: 16px;"
              >check</i>
            </el-button>
            <el-button
              type="secondary"
              data-tested-as="editSendingSpeedCancelButton"
              size="small"
              @click="isEditSpeedMode = showEditSendingSpeedWarning = showEditSendingSpeedLimitWarning = false"
            >
              <i
                class="material-icons"
                style="font-size: 15px;"
              >close</i>
            </el-button>
          </span>

          <span
            v-else
            class="order-details-sending-speed-span"
          >
            <span v-if="order.sto">
              <span>{{ $t("CAMPAIGN_DETAIL_STO_LABEL") }}&nbsp;</span>
              <el-tooltip
                v-if="hasWrittingPerm"
                class="item"
                effect="dark"
                :content="$i18n.t('CAMPAIGN_DETAIL_STO_TOOLTIP')"
                placement="top"
              >
                <i class="el-icon-question" />
              </el-tooltip>
              <el-tooltip
                v-else
                class="item"
                effect="dark"
                :content="$i18n.t('CAMPAIGN_DETAIL_STO_TOOLTIP_WITHOUT_PERM')"
                placement="top"
              >
                <i class="el-icon-question" />
              </el-tooltip>
            </span>
            <span
              v-else-if="formattedSendingSpeed"
              data-tested-as="editSendingSpeedDisplay"
            >
              {{ formattedSendingSpeed }}
              <el-tooltip
                v-if="order.status_id == orderStatuses.SCHEDULED"
                class="item"
                effect="dark"
                :content="$i18n.t('CAMPAIGN_DETAIL_SENDING_SPEED_NOT_EDITABLE')"
                placement="top"
              >
                <i class="el-icon-question" />
              </el-tooltip>
            </span>
            <span
              v-else
            >
              N/A
              <el-tooltip
                v-if="hasWrittingPerm"
                class="item"
                effect="dark"
                :content="$i18n.t('CAMPAIGN_DETAIL_N/A_TOOLTIP')"
                placement="top"
              >
                <i class="el-icon-question" />
              </el-tooltip>
            </span>
            <el-button
              v-if="isSendingSpeedEditable"
              data-tested-as="editSendingSpeedButton"
              type="text"
              style="margin-left: 4px"
              @click="triggerEditSpeedMode"
            >
              <i
                class="material-icons edit-sending-speed-button"
              >edit</i>
            </el-button>
          </span>
          <span
            v-if="order.sending_speed_last_update"
            class="sending-speed-last-update"
          >
            {{ $t('CAMPAIGN_DETAIL_SENDING_SPEED_LAST_EDIT', {timeDifference: sendingSpeedLastEdit}) }}
          </span>
        </el-col>
      </el-row>
      <el-row
        class="order-details-row"
      >
        <div
          v-if="showEditSendingSpeedLimitWarning"
          class="order-error-description sending-speed-edit-warning"
          data-tested-as="editSendingSpeedLimitWarning"
        >
          <i
            class="el-icon-error"
          />
          <span v-if="unformatNumber(newSendingSpeed) > max_sending_speed">
            {{ $t(`CAMPAIGN_DETAIL_SENDING_SPEED_MAX`, {sendingSpeed: formatNumber(max_sending_speed)}) }}
          </span>
          <span v-else>
            {{ $t(`CAMPAIGN_DETAIL_SENDING_SPEED_MIN`, {sendingSpeed: formatNumber(min_sending_speed)}) }}
          </span>
        </div>
        <div
          v-if="showEditSendingSpeedWarning"
          class="order-warning-description sending-speed-edit-warning"
          data-tested-as="editSendingSpeedWarning"
        >
          <i
            class="el-icon-warning"
          />
          <span>
            {{ $t(`CAMPAIGN_DETAIL_SENDING_SPEED_WARNING`) }}
          </span>
        </div>
      </el-row>

      <el-row
        type="flex"
        justify="space-between"
        class="order-details-row"
      >
        <el-col
          :span="10"
          class="text-left"
        >
          {{ $t('CAMPAIGN_DETAIL_ID') }}:
        </el-col>
        <el-col
          :span="10"
          class="text-right"
        >
          <span v-if="order.campaign_id">
            {{ order.campaign_id }}
          </span>
        </el-col>
      </el-row>
      <el-row
        type="flex"
        justify="space-between"
        class="order-details-row"
      >
        <el-col
          :span="8"
          class="text-left"
        >
          {{ $t("SENDING_ORDER_ID") }}
        </el-col>

        <el-col
          :span="6"
          class="text-right"
        >
          {{ order.id }}
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { statusOrder } from '@/mixins/order'
import config from '@/config'
import DisplayCrmIds from '@/components/Order/DisplayCrmIds'
import AvatarHolder from '@/components/UI/AvatarHolder.vue'
import { getInitials } from '@/filters'

export default {
  name: 'OrderDetails',
  components: { DisplayCrmIds, AvatarHolder },
  mixins: [statusOrder],
  props: {
    order: { type: Object, required: true },
    isMonitoring: { type: Boolean, default: false }
  },
  data () {
    return {
      refChannels: config.CHANNELS,
      orderStatuses: config.SENDING_ORDER_STATUS,
      isEditSpeedMode: false,
      newSendingSpeed: 0,
      isLoadingUpdateSendingSpeed: false,
      showEditSendingSpeedWarning: false,
      showEditSendingSpeedLimitWarning: false,
      max_sending_speed: config.MAX_SENDING_SPEED,
      min_sending_speed: config.MIN_SENDING_SPEED,
    }
  },
  computed: {
    user () {
      return this.$store.getters['auth/user']
    },
    canResend () {
      return this.$store.getters['auth/hasPermission']('watchly:user:routing-errors')
    },
    hasWrittingPerm () {
      return this.$store.getters['auth/hasPermission']('watchly:user:campaigns:w')
    },
    canCancelSending () {
      return this.isError && !this.order.ignore_date && this.canResend && !this.resendInProgress
    },
    isLoadingDetails () {
      return this.$store.getters['order/isLoadingDetails']
    },
    isMultiClients () {
      return this.$store.getters['auth/isMultiClients']
    },
    client () {
      return this.$store.getters['auth/getClient'](this.order.client_id)
    },
    group () {
      return this.$store.getters['auth/getGroup'](this.order.group_id)
    },
    progressValue () {
      if (this.isSent && !this.order.volume) {
        return 100
      }
      if (this.order.volume && this.order.volumes_sent) {
        return parseInt(this.order.volumes_sent / this.order.volume * 100)
      }
      return 0
    },
    canBeUnscheduled () {
      return this.isScheduled || this.isProcessing || this.isReadyToSend
    },
    canBePaused () {
      let isProgressing =  !this.isError && !this.isPaused && !this.isSent
      let isTargetReady = this.isStarted && !this.isProcessing
      let isEmail = this.order.channel_id == this.refChannels.EMAIL
      let isPushNotification = this.order.channel_id == this.refChannels.PUSH_NOTIF

      return isProgressing && isTargetReady && (isEmail || isPushNotification)
    },
    progressColor () {
      if (this.isPaused) {
        return 'rgba(230, 162, 60, 1)'
      } else if (this.isStarted) {
        return 'rgba(74, 144, 226, 1)'
      } else {
        return 'rgba(31, 178, 111, 1)'
      }
    },
    statusIcon () {
      if (this.isPaused) {
        return 'play_arrow'
      } else if (this.isStarted) {
        return 'pause'
      } else {
        return ''
      }
    },
    startDate () {
      if (this.order.sto && this.order.estimated_start_date) {
        return moment(this.order.estimated_start_date)
        .tz(this.user.timezone)
        .format('LLL')
      }
      return moment(this.order.sending_date)
        .tz(this.user.timezone)
        .format('LLL')
    },
    estimatedVolume () {
      return this.order.estimated_volume === null ? 'N/A' : this.order.estimated_volume
    },
    endDate () {
      if (this.order.sending_end_date == null) {
        if (this.order.estimated_end_date === null) {
          return 'N/A'
        }
        if (this.isPaused) {
          return this.$i18n.t('ORDER_STATUS_PAUSE')
        } else {
          return moment(this.order.estimated_end_date)
            .tz(this.user.timezone)
            .format('LLL')
        }
      } else {
        return moment(this.order.sending_end_date)
          .tz(this.user.timezone)
          .format('LLL')
      }
    },
    endDateLabel () {
      return this.$i18n.t(`CAMPAIGN_DETAIL_END_DATE${this.order.sending_end_date ? '' : '_ESTIMATED'}`)
    },
    resendInFailure () {
      const err = this.order.error
      return err && err.resent_date && (
        err.resent_date < err.creation_date || err.resent_status === config.RESEND_STATUS.ERROR
      )
    },
    resendInProgress () {
      return this.order.error && this.order.error.resent_status === config.RESEND_STATUS.ONGOING
    },
    resentDate () {
      if (!this.order.error || !this.order.error.resent_date) {
        return ''
      }
      return moment(this.order.error.resent_date)
        .tz(this.user.timezone)
        .format('L [' + this.$t('AT') + '] LT')
    },
    ignoreDate () {
      if (!this.order.ignore_date) {
        return ''
      }
      return moment(this.order.ignore_date)
        .tz(this.user.timezone)
        .format('L [' + this.$t('AT') + '] LT')
    },
    pauseDate () {
      if (!this.order.paused_at) {
        return ''
      }
      return moment(this.order.paused_at)
        .tz(this.user.timezone)
        .format('L [' + this.$t('AT') + '] LT')
    },
    pausedBy () {
      return this.order.paused_by || {}
    },
    failureDate () {
      if (!this.order.error) {
        return ''
      }
      return moment(this.order.error.creation_date)
        .tz(this.user.timezone)
        .format('L [' + this.$t('AT') + '] LT')
    },
    avatarInfo () {
      let returned_info = {}
      if (this.group) {
        returned_info = {
          name: this.group.name,
          logo: this.group.logo_url || null
        }
      }
      if (this.isMultiClients && this.client) {
        returned_info.name = this.client.name
        returned_info.logo = this.client.logo_url || null
      }
      return returned_info
    },
    formattedSendingSpeed() {
      const sendingSpeed = this.order.sending_speed
      return this.formatNumber(sendingSpeed)
    },
    isSendingSpeedEditable() {
      return !!this.order.sending_speed
        && this.hasWrittingPerm
        && !this.order.sto
        && (
          this.order.status_id === this.orderStatuses.SENDING_IN_PROGRESS
          || (
              this.order.status_id === this.orderStatuses.ERROR
              && this.order.processing_status !== 'CANCELLED'
             )
          || this.order.status_id === this.orderStatuses.PAUSED
        )
    },
    sendingSpeedLastEdit() {
      if (!this.order.sending_speed_last_update) {
        return null
      }
      moment.locale(this.$i18n.locale)
      const last_update_date = moment(this.order.sending_speed_last_update)
      return moment(last_update_date).fromNow(true)
    },
  },
  created () {
    // config.CHANNELS
    this.channelTags = {
      1: {
        icon: 'mail_outline',
        title: 'Email'
      },
      2: {
        icon: 'phone_iphone',
        title: this.$t("SMS")
      },
      3: {
        icon: 'notifications',
        title: 'Push notification'
      }
    }
    // config.CAMPAIGN_TYPE
    this.typeTags = {
      1: {
        icon: 'send',
        title: this.$t('ONE_SHOT')
      },
      2: {
        icon: 'autorenew',
        title: this.$t('AUTOMATIC')
      },
      4: {
        icon: 'arrow_forward',
        title: this.$t('ON_DEMAND')
      }
    }
  },
  methods: {
    volumeTranslation (estimated = false) {
      let suffix = ''
      if (estimated) {
        suffix = '_ESTIMATED'
      }
      if (this.order.is_ab_test) {
        suffix += '_TEST'
      } else if (this.order.is_generalization) {
        suffix += '_GENE'
      }
      return this.$t(`CAMPAIGN_DETAIL_VOLUME${suffix}`)
    },
    getInitials: getInitials,
    formatNumber (rawNumber) {
      if (rawNumber) {
        // convert 1000 => 1 000
        return rawNumber.toLocaleString('fr-FR')
      }
    },
    unformatNumber (numberString) {
      return parseInt(numberString.replace(/\s/g, ''), 10)
    },
    triggerEditSpeedMode () {
      this.isEditSpeedMode = true
      this.newSendingSpeed = this.formatNumber(this.order.sending_speed)
      if (this.order.channel_id == this.refChannels.EMAIL && this.order.sending_speed >= config.EMAIL_SENDING_SPEED_WARNING){
        this.showEditSendingSpeedWarning = true
      }
    },
    processSendingSpeedInput(newValue) {
      this.showEditSendingSpeedWarning = false
      this.showEditSendingSpeedLimitWarning = false
      const parsedValue = this.unformatNumber(newValue)
      if (!isNaN(parsedValue)) {
        if (this.order.channel_id == this.refChannels.EMAIL && parsedValue >= config.EMAIL_SENDING_SPEED_WARNING){
          this.showEditSendingSpeedWarning = true
        }
        this.newSendingSpeed = this.formatNumber(parsedValue)
        if (parsedValue > this.max_sending_speed || parsedValue < this.min_sending_speed) {
          this.showEditSendingSpeedLimitWarning = true
        }
      } else if (newValue === '') {
        this.newSendingSpeed = '';
      }
    },
    submitSendingSpeed () {
      if (this.unformatNumber(this.newSendingSpeed) > this.max_sending_speed || this.unformatNumber(this.newSendingSpeed) < this.min_sending_speed){
        return
      }
      this.isLoadingUpdateSendingSpeed = true
      this.$store
        .dispatch(
          'order/updateSendingSpeed',
          {
            orderId: this.order.id,
            sendingSpeed: this.unformatNumber(this.newSendingSpeed)
          }
        )
        .then(() => {
          this.$emit('refresh-detail', false);
          this.isEditSpeedMode = this.showEditSendingSpeedWarning = false
        })
        .catch(error => {
            this.$emit('notify-from-error', error);
        })
        .finally(() => {
          this.isLoadingUpdateSendingSpeed = false
        })
    }
  },
}
</script>

<style lang="scss">
.edit-sending-speed-span {
  .el-input__inner, button {
    height: 32px;
    margin-left: 4px !important;
  }
  .el-input__inner {
    width: 104px
  }
  button {
    width: 36px;
    padding: 3px 8px;
  }
}
</style>
<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";

@media screen and (min-width: 800px) {
  .order-details-container {
    padding-left: 40px;
    padding-right: 40px;
  }
}
.order-details-modal-body {
  text-align: center;
  width: 100%;
  margin: auto;
}

.order {
  &-resume {color: $--color-primary;}
  &-paused {color: $--color-warning;}
  &-sent {color: $--color-success;}
  &-error {color: $--color-danger;}
}

.order-details-sending-speed-span {
  display: flex;
  align-items: center;
  justify-content: right;
}
.edit-sending-speed-button {
  font-size: 16px;
  color: $--color-text-regular;
}
.sending-speed-last-update {
  color: $--color-text-secondary;
  font-size: 10px;
}

.sending-speed-edit-warning {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 0px !important;
  line-height: 25px;

  .el-icon-warning{
    margin-right: 5px;
  }
}


.order-details-row {
  border-bottom: 1px solid #ebeceb;
  line-height: 50px;
}

.order-details-container .channel-row i {
  font-size: 36px;
  color: #ccc;
}

.bigger-title {
  line-height: 20px;
}

.order-details-header {
  display: flex;
  &-container {
    text-align: left;
  }
  &-container {
    > div:not(:first-child) {
      margin-top: 20px;
    }
    .tags {
      display: flex;
      > div {
        margin-right: 15px;
        i {
          margin-right: 8px;
          font-size: 16px;
        }
      }
    }
    .el-row {
      margin-bottom: 15px;
    }
  }
}

hr {
  border: 1px solid $--color-separator;
}

.align-center {
  display: flex;
  align-items: center;
}

.order-details-idm {
  line-height: 40px;
}

.progress-bar {
  &-title {
    margin-bottom: 10px;
    text-align: left;
  }
}

.order-error-help,
.order-error-description,
.order-warning-description {
  background-color: $--color-details-error;
  margin-top: 20px;
  font-size: 12px;
  padding: 10px;
  border-radius: 5px;
  text-align: left;
  word-break: normal;

  i {
    float: left;
    font-size: 16px;
    margin-left: 5px;
    margin-top: 1px;
    color: $--color-light-black;
  }

  i + div {
    margin-left: 30px;
  }
}

.interrupted {
  vertical-align: sub;
  font-size:15px !important;
  color:#4a90e2;
}

.order-error-description {
  color: $--color-danger;
  background: $--color-danger-lighter;
  .el-icon-error{
    float: none;
    margin-right: 5px;
    color: $--color-danger;
  }
}

.order-warning-description {
  color: $--color-warning;
  background: $--color-warning-lighter;

  i {
    color: $--color-warning;
  }
}

::v-deep .order-button {
  padding: 10px 12px;

  span {
    display: flex;
    align-items: center;

    i {
      font-size: 16px;
      margin-right: 6px;
    }
  }
}

::v-deep .el-loading-spinner {
  top: 20%;
}

.cancel-campaign {
  display: flex;
  justify-content: flex-start;
  margin-top: 25px;
}

.flex-center {
  display: flex;
  align-items: center;
}

</style>
