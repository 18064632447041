import config from '../config'
import Vue from 'vue'
import axios from 'axios'

const CancelToken = axios.CancelToken
let fluxCancel = {}
let orderCancel

const SET_ORDERS = 'SET_ORDERS'
const SET_IS_ONLINE = 'SET_IS_ONLINE'
const SET_IS_CANCELLED = 'SET_IS_CANCELLED'
const SET_ALL_ORDERS = 'SET_ALL_ORDERS'
const SET_LOADING = 'SET_LOADING'
const SET_LOADING_MORE_ORDERS = 'SET_LOADING_MORE_ORDERS'
const SET_CANCELLED_SEARCH = 'SET_CANCELLED_SEARCH'
const SET_LOADING_DETAILS = 'SET_LOADING_DETAILS'
const RESET_ORDERS_MAP = 'RESET_ORDERS_MAP'
const EXTEND_ORDERS = 'EXTEND_ORDERS'
const EXTEND_ALL_ORDERS = 'EXTEND_ALL_ORDERS'
const ORDER_FLUX = 'ORDER_FLUX'
const SET_FULL_LOADED_EMAIL = 'SET_FULL_LOADED_EMAIL'
const SET_FULL_LOADED_MOBILE = 'SET_FULL_LOADED_MOBILE'
const SET_FULL_LOADED_ALL_ORDERS = 'SET_FULL_LOADED_ALL_ORDERS'
const SET_FULL_LOADED_PUSH_NOTIF = 'SET_FULL_LOADED_PUSH_NOTIF'
const SET_PARAMS = 'SET_PARAMS'
const RESET_FILTERS = 'RESET_FILTERS'
const UPDATE_ORDER_PROPERTY = 'UPDATE_ORDER_PROPERTY'
const SET_COUNT_ORDERS_IN_ERROR = 'SET_COUNT_ORDERS_IN_ERROR'
const SET_DETAIL_ORDER = 'SET_DETAIL_ORDER'

const defaultParams = {
  'groups': [],
  'clients': [],
  's': '',
  'channelId': config.CHANNELS.EMAIL
}

const moduleOrders = {
  namespaced: true,
  state: {
    isLoading: false,
    isLoadingMoreOrders: false,
    isOnline: true,
    cancelledSearch: false,
    isCancelled: false,
    isLoadingDetails: false,
    ordersMap: {},
    allOrders: [],
    order: {},
    countOrdersInError: {},
    loadedAllOrders: false,
    loadedAllEmails: false,
    loadedAllMobiles: false,
    loadedAllPushNotifs: false,
    params: defaultParams
  },
  mutations: {
    [SET_FULL_LOADED_EMAIL] (state, value) {
      Vue.set(state, 'loadedAllEmails', value)
    },
    [SET_FULL_LOADED_MOBILE] (state, value) {
      Vue.set(state, 'loadedAllMobiles', value)
    },
    [SET_FULL_LOADED_PUSH_NOTIF] (state, value) {
      Vue.set(state, 'loadedAllPushNotifs', value)
    },
    [SET_LOADING] (state, value) {
      Vue.set(state, 'isLoading', value)
    },
    [SET_LOADING_MORE_ORDERS] (state, value) {
      Vue.set(state, 'isLoadingMoreOrders', value)
    },
    [SET_CANCELLED_SEARCH] (state, value) {
      Vue.set(state, 'cancelledSearch', value)
    },
    [SET_IS_ONLINE] (state, value) {
      Vue.set(state, 'isOnline', value)
    },
    [SET_IS_CANCELLED] (state, value) {
      Vue.set(state, 'isCancelled', value)
    },
    [SET_FULL_LOADED_ALL_ORDERS] (state, value) {
      Vue.set(state, 'loadedAllOrders', value)
    },
    [SET_LOADING_DETAILS] (state, value) {
      Vue.set(state, 'isLoadingDetails', value)
    },
    [SET_ORDERS] (state, { orders, channelId }) {
      Vue.set(state.ordersMap, channelId, orders)
    },
    [SET_ALL_ORDERS] (state, value) {
      Vue.set(state, 'allOrders', value)
    },
    [SET_COUNT_ORDERS_IN_ERROR] (state, { channelId, total }) {
      Vue.set(state.countOrdersInError, channelId, total)
    },
    [SET_DETAIL_ORDER] (state, order) {
      Vue.set(state, 'order', order)
    },
    [EXTEND_ALL_ORDERS] (state, orders) {
      let cleanArray = []
      for (let i = 0; i < orders.length; i++) {
        const isDedup = state.allOrders.find(el => el.id === orders[i].id)
        if (!isDedup) {
          cleanArray.push(orders[i])
        }
      }
      const newArray = state.allOrders.concat(cleanArray)
      Vue.set(state, 'allOrders', newArray)
    },
    [EXTEND_ORDERS] (state, { orders, channelId }) {
      let ordersGeneric = state.ordersMap[channelId] ? state.ordersMap[channelId] : []
      let dedup = {}
      let finalOrdersList = []
      for (let i = 0; i < ordersGeneric.length; i++) {
        if (!dedup[ordersGeneric[i].id]) {
          finalOrdersList.push(ordersGeneric[i])
          dedup[ordersGeneric[i].id] = true
        }
      }
      for (let i = 0; i < orders.length; i++) {
        if (!dedup[orders[i].id]) {
          finalOrdersList.push(orders[i])
          dedup[orders[i].id] = true
        }
      }
      Vue.set(state.ordersMap, channelId, finalOrdersList)
    },
    [ORDER_FLUX] (state, { newOrder, updatedOrder, channelId, global = false }) {
      let ordersGeneric = global ? state.allOrders : state.ordersMap[channelId]
      if (ordersGeneric) {
        const updatedOrderId = updatedOrder.map(u => u.id)
        ordersGeneric = ordersGeneric.filter(order => updatedOrderId.includes(order.id))
        // Update existing order
        for (let i = 0; i < updatedOrder.length; i++) {
          for (let j = 0; j < ordersGeneric.length; j++) {
            if (updatedOrder[i].id === ordersGeneric[j].id) {
              ordersGeneric[j] = Object.assign(ordersGeneric[j], updatedOrder[i])
            }
          }
        }
        // Inject new order and reorder the whole list by sending_date
        ordersGeneric = [...ordersGeneric, ...newOrder].sort(function (a, b) {
          return new Date(b.sending_date) - new Date(a.sending_date)
        })
        if (global) {
          Vue.set(state.allOrders, ordersGeneric)
        } else {
          Vue.set(state.ordersMap, channelId, ordersGeneric)
        }
      }
    },
    [RESET_ORDERS_MAP] (state) {
      Vue.set(state, 'ordersMap', {})
      Vue.set(state, 'loadedAllEmails', false)
      Vue.set(state, 'loadedAllMobiles', false)
      Vue.set(state, 'loadedAllPushNotifs', false)
    },
    [SET_PARAMS] (state, params) {
      const newParams = Object.assign(state.params, params)
      Vue.set(state, 'params', newParams)
    },
    [RESET_FILTERS] (state) {
      Object.assign(state.params, {
        'status': '',
        'groups': [],
        'clients': [],
        's': ''
      })
    },
    [UPDATE_ORDER_PROPERTY] (state, { orderId, key, value }) {
      if (state.ordersMap[config.CHANNELS.EMAIL]) {
        let order = state.ordersMap[config.CHANNELS.EMAIL].find(o => o.id === orderId)
        if (order) {
          Vue.set(order, key, value)
          return
        }
      }
      if (state.ordersMap[config.CHANNELS.MOBILE]) {
        let order = state.ordersMap[config.CHANNELS.MOBILE].find(o => o.id === orderId)
        if (order) {
          Vue.set(order, key, value)
          return
        }
      }
      if (state.ordersMap[config.CHANNELS.PUSH_NOTIF]) {
        let order = state.ordersMap[config.CHANNELS.PUSH_NOTIF].find(o => o.id === orderId)
        if (order) {
          Vue.set(order, key, value)
        }
      }
    },
  },
  actions: {
    params ({ commit }, params) {
      commit(SET_PARAMS, params)
    },
    setIsOnline ({ commit }, value) {
      commit(SET_IS_ONLINE, value)
    },
    list ({ commit, state }, actionParams) {
      commit(SET_LOADING, true)
      let url = `${config.API_URL}/orders`
      let reqParams = actionParams.global
        ? { l: actionParams.l, is_monitoring: true }
        : { channel_id: actionParams.channelId, l: actionParams.l }
      if (orderCancel) { orderCancel() }
      if (actionParams.groups && actionParams.groups.length) { reqParams.groups = actionParams.groups.map(v => v.id).join(',') }
      if (actionParams.clients && actionParams.clients.length) { reqParams.clients = actionParams.clients.map(v => v.id).join(',') }
      if (actionParams.s) { reqParams.s = actionParams.s }

      reqParams.status = actionParams.status && actionParams.status.length ? actionParams.status : '1,2,3,4,6'
      return new Promise((resolve, reject) => {
        Vue.axios
          .get(url, {
            params: reqParams,
            cancelToken: new CancelToken(function executor (c) {
              orderCancel = c
            })
          })
          .then(response => {
            if (actionParams.global) {
              commit(SET_ALL_ORDERS, response.data)
              if (response.data.length < reqParams.l) {
                commit(SET_FULL_LOADED_ALL_ORDERS, true)
              }
            } else {
              commit(SET_ORDERS, {
                orders: response.data,
                channelId: actionParams.channelId
              })
              if (response.data.length < reqParams.l) {
                if (actionParams.channelId === config.CHANNELS.EMAIL) { commit('SET_FULL_LOADED_EMAIL', true) }
                if (actionParams.channelId === config.CHANNELS.MOBILE) { commit('SET_FULL_LOADED_MOBILE', true) }
                if (actionParams.channelId === config.CHANNELS.PUSH_NOTIF) { commit('SET_FULL_LOADED_PUSH_NOTIF', true) }
              }
            }
            if (response.status >= 300) { reject(response) }
            resolve(response)
          })
          .catch(error => {
            if (!axios.isCancel(error)) {
              reject(error)
            }
            commit(SET_CANCELLED_SEARCH, true)
          })
          .finally(() => {
            if (!state.cancelledSearch) commit(SET_LOADING, false)
            commit(SET_CANCELLED_SEARCH, false)
          })
      })
    },
    listExtend ({ commit, state }, actionParams) {
      let url = `${config.API_URL}/orders`
      let reqParams = actionParams.global
        ? { l: actionParams.l, is_monitoring: true }
        : { channel_id: actionParams.channelId, l: actionParams.l }

      commit(SET_LOADING_MORE_ORDERS, true)
      if (orderCancel) { orderCancel() }
      if (actionParams.groups && actionParams.groups.length) { reqParams.groups = actionParams.groups.map(v => v.id).join(',') }
      if (actionParams.clients && actionParams.clients.length) { reqParams.clients = actionParams.clients.map(v => v.id).join(',') }
      if (actionParams.s) { reqParams.s = actionParams.s }

      reqParams.status = actionParams.status && actionParams.status.length ? actionParams.status : '1,2,3,4,6'

      // We added 0 in default case, but we should never enter in this case
      // it mean we switched of channel_id and we extended before the classic list call
      // got returned !
      if (!actionParams.global) {
        reqParams.offset = state.ordersMap[actionParams.channelId]
          ? state.ordersMap[actionParams.channelId].length : 0
      } else {
        reqParams.offset = state.allOrders.length || 0
      }

      return new Promise((resolve, reject) => {
        Vue.axios
          .get(url, {
            params: reqParams,
            cancelToken: new CancelToken(function executor (c) {
              orderCancel = c
            })
          })
          .then(response => {
            if (actionParams.global) {
              commit(EXTEND_ALL_ORDERS, response.data)
              if (response.data.length < reqParams.l) {
                commit(SET_FULL_LOADED_ALL_ORDERS, true)
              }
            } else {
              commit(EXTEND_ORDERS, { orders: response.data, channelId: actionParams.channelId })
              if (response.data.length < reqParams.l) {
                if (actionParams.channelId === config.CHANNELS.EMAIL) { commit('SET_FULL_LOADED_EMAIL', true) }
                if (actionParams.channelId === config.CHANNELS.MOBILE) { commit('SET_FULL_LOADED_MOBILE', true) }
                if (actionParams.channelId === config.CHANNELS.PUSH_NOTIF) { commit('SET_FULL_LOADED_PUSH_NOTIF', true) }
              }
            }

            if (response.status >= 300) { reject(response) }
            resolve(response)
          })
          .catch(error => {
            if (!axios.isCancel(error)) {
              reject(error)
            }
            commit(SET_CANCELLED_SEARCH, true)
          })
          .finally(() => {
            commit(SET_LOADING_MORE_ORDERS, false)
            commit(SET_CANCELLED_SEARCH, false)
          })
      })
    },
    flux ({ commit }, { channelId, params, from, ids, global = false }) {
      let url = `${config.API_URL}/orders/flux`
      let reqParams = {
        from: from,
        ids: ids
      }
      if (!global) {
        reqParams.channel_id = channelId
        if (params.groups.length) { reqParams.groups = params.groups.map(v => v.id).join(',') }
        if (params.clients.length) { reqParams.clients = params.clients.map(v => v.id).join(',') }
        if (params.s) { reqParams.s = params.s }
        reqParams.status = params.status && params.status.length ? params.status : '1,2,3,4,6'
      } else {
        reqParams.is_monitoring = true
      }

      return new Promise((resolve, reject) => {
        Vue.axios
          .post(url, reqParams, {
            cancelToken: new CancelToken(function executor (c) {
              fluxCancel[channelId] = c
            })
          })
          .then(response => {
            if (global) {
              commit(ORDER_FLUX, {
                global: true,
                updatedOrder: response.data['updated'],
                newOrder: response.data['new']
              })
            } else {
              commit(ORDER_FLUX, {
                channelId: channelId,
                updatedOrder: response.data['updated'],
                newOrder: response.data['new']
              })
            }
            commit(SET_LOADING, false)

            if (response.status >= 300) { reject(response) }
            resolve(response)
          })
          .catch(error => {
            if (!axios.isCancel(error)) {
              reject(error)
            }
          })
      })
    },
    countOrderInError ({ commit }, { channelId }) {
      const url = `${config.API_URL}/orders/count?status=${config.SENDING_ORDER_STATUS.ERROR}&channel_id=${channelId}`
      return new Promise((resolve, reject) => {
        Vue.axios.get(url)
          .then(response => {
            if (response.status >= 300) {
              reject(new Error('Incorrect status'))
            }
            commit(SET_COUNT_ORDERS_IN_ERROR, { channelId, total: response.data['total'] })
            resolve(response.data)
          })
          .catch(error => { reject(error) })
      })
    },
    updateStatus ({ commit }, { orderId, status, cancel = false }) {
      const url = `${config.API_URL}/orders/${orderId}`
      if (cancel) { commit(SET_IS_CANCELLED, true) }
      return new Promise((resolve, reject) => {
        Vue.axios.patch(url, {
          status: status,
          is_cancelled: cancel
        })
          .then(response => {
            if (response.status >= 300) {
              reject(new Error('Incorrect status'))
            }
            if (!cancel) {
              commit(UPDATE_ORDER_PROPERTY, {
                orderId: orderId,
                key: 'status_id',
                value: status === config.ORDER_STATUS.PAUSED ? config.SENDING_ORDER_STATUS.PAUSED : config.SENDING_ORDER_STATUS.SENDING_IN_PROGRESS
              })
            } else {
              commit(SET_IS_CANCELLED, false)
            }
            resolve(response.data)
          })
          .catch(error => { reject(error) })
      })
    },
    updateSendingSpeed ({ commit }, { orderId, sendingSpeed }) {
      const url = `${config.API_URL}/orders/${orderId}`
      return new Promise((resolve, reject) => {
        Vue.axios.patch(url, {
          sending_speed: sendingSpeed
        })
          .then(response => {
            if (response.status >= 300) {
              reject(response)
            }
            commit(UPDATE_ORDER_PROPERTY, { orderId, key: 'sending_speed', value: sendingSpeed } )
            resolve(sendingSpeed)
          })
          .catch(error => {
            commit(UPDATE_ORDER_PROPERTY, { orderId, key: 'sending_speed', value: sendingSpeed } )
            if (!axios.isCancel(error)) {
              reject(error)
            }
          })
      })
    },
    preload ({ commit }, order) {
      commit(SET_DETAIL_ORDER, order)
    },
    details ({ commit }, { orderId, refresh = false }) {
      if (!refresh) {
        commit(SET_LOADING_DETAILS, true)
      }

      if (orderCancel) { orderCancel() }

      const url = `${config.API_URL}/orders/${orderId}`
      return new Promise((resolve, reject) => {
        Vue.axios.get(url, {
          cancelToken: new CancelToken(function executor (c) {
            orderCancel = c
          })
        })
          .then(response => {
            if (response.status >= 300) {
              reject(response)
            }
            commit(SET_DETAIL_ORDER, response.data)
            resolve(response)
          })
          .catch(error => {
            if (!axios.isCancel(error)) {
              reject(error)
            }
          })
          .finally(() => {
            if (!refresh) {
              commit(SET_LOADING_DETAILS, false)
            }
          })
      })
    },
    resend (_, { orderId }) {
      const url = `${config.API_URL}/orders/${orderId}/resend`
      return new Promise((resolve, reject) => {
        Vue.axios.post(url, {
          cancelToken: new CancelToken(function executor (c) {
            orderCancel = c
          })
        })
          .then(response => {
            (response.status >= 300 ? reject : resolve)(response)
          })
          .catch(error => {
            if (!axios.isCancel(error)) {
              reject(error)
            }
          })
      })
    },
    ignore (_, { orderId }) {
      const url = `${config.API_URL}/orders/${orderId}/ignore`
      return new Promise((resolve, reject) => {
        Vue.axios.post(url, {
          cancelToken: new CancelToken(function executor (c) {
            orderCancel = c
          })
        })
          .then(response => {
            (response.status >= 300 ? reject : resolve)(response)
          })
          .catch(error => {
            if (!axios.isCancel(error)) {
              reject(error)
            }
          })
      })
    },
    reset ({ commit }) {
      commit(RESET_ORDERS_MAP)
      // Cancel all current flux
      for (let channelId in fluxCancel) {
        fluxCancel[channelId]()
      }
    },
    hardReset ({ commit }) {
      commit(RESET_FILTERS)
    }
  },
  getters: {
    allOrders: state => state.allOrders,
    isLoading: state => state.isLoading,
    isLoadingMore: state => state.isLoadingMoreOrders,
    isOnline: state => state.isOnline,
    isCancelled: state => state.isCancelled,
    isLoadingDetails: state => state.isLoadingDetails,
    // New way to get orders
    params: state => state.params,
    order: state => state.order,
    emails: state => {
      if (!state.ordersMap[config.CHANNELS.EMAIL]) {
        return []
      }
      return state.ordersMap[config.CHANNELS.EMAIL]
    },
    isAllOrdersLoaded: state => {
      return state.loadedAllOrders
    },
    errorsInChannel: state => (channelId) => {
      return state.countOrdersInError[channelId] || 0
    },
    isEmailsLoaded: state => {
      return state.ordersMap[config.CHANNELS.EMAIL]
    },
    isEmailsAllLoaded: state => {
      return state.loadedAllEmails
    },
    mobiles: state => {
      if (!state.ordersMap[config.CHANNELS.MOBILE]) {
        return []
      }
      return state.ordersMap[config.CHANNELS.MOBILE]
    },
    isMobilesLoaded: state => {
      return state.ordersMap[config.CHANNELS.MOBILE]
    },
    isMobilesAllLoaded: state => {
      return state.loadedAllMobiles
    },
    pushNotifs: state => {
      if (!state.ordersMap[config.CHANNELS.PUSH_NOTIF]) {
        return []
      }
      return state.ordersMap[config.CHANNELS.PUSH_NOTIF]
    },
    isPushNotifsLoaded: state => {
      return state.ordersMap[config.CHANNELS.PUSH_NOTIF]
    },
    isPushNotifsAllLoaded: state => {
      return state.loadedAllPushNotifs
    }
  }
}

export default moduleOrders
