<template>
  <div @click="$emit('open-details', order.id)">
    <el-card
      shadow="hover"
      :class="isScheduled ? 'scheduled' : 'current'"
    >
      <div class="content">
        <div :class="['align', isScheduled ? 'align-padding' : 'content-margin']">
          <i class="material-icons md-18">
            {{ iconChannelOrder }}
          </i>
          <span class="content-title">
            {{ order.client_id }}
          </span>
        </div>
        <div v-if="isScheduled">
          <div class="align">
            <i class="material-icons icon">
              {{ iconStatusOrder }}
            </i>
            {{ formatDate(order.sending_date) }}
          </div>
        </div>
        <div
          v-else
          :class="{ 'status-error': isError }"
        >
          <div>
            <span
              class="align content-margin"
            >
              <i :class="['material-icons icon', isErrorAndOnline ? 'icon-error': 'offline' ]">
                {{ iconStatusOrder }}
              </i>
              <span :class="[{'content-error': isError}, { 'offline': !isOnline }]">
                {{ isError ? $t('MONITORING.ERROR') : endDate }}
              </span>
              <span
                v-if="isEndingInNDays"
                class="diff-days"
              >
                +{{ isEndingInNDays }}
              </span>
            </span>
          </div>
          <div
            v-if="isProcessing"
            :class="['processing', { 'offline': !isOnline }]"
          >
            {{ $t("MONITORING.PROCESSING") }}
          </div>
          <div
            v-else
          >
            <el-progress
              :text-inside="true"
              :stroke-width="12"
              :percentage="100 / 10"
              :color="progressColor"
            />
          </div>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import config from '@/config'
import { statusOrder } from '@/mixins/order'

export default {
  name: 'MonitoringCard',
  mixins: [statusOrder],
  props: {
    order: {
      type: Object,
      required: true
    }
  },
  computed: {
    user () {
      return this.$store.getters['auth/user']
    },
    isOnline () {
      return this.$store.getters['order/isOnline']
    },
    isErrorAndOnline () {
      return this.isError && this.isOnline
    },
    iconChannelOrder () {
      if (this.order.channel_id === config.CHANNELS.EMAIL) {
        return 'mail_outline'
      } else if (this.order.channel_id === config.CHANNELS.MOBILE) {
        return 'stay_current_portrait'
      } else {
        return 'notifications_none'
      }
    },
    iconStatusOrder () {
      if (this.isError) {
        return 'error_outline'
      } else if (this.isScheduled) {
        return 'event_note'
      } else {
        return 'timer'
      }
    },
    endDate () {
      if (this.order.sending_end_date == null) {
        if (this.order.volume == null) {
          return 'N/A'
        } else {
          const volume = this.order.volumes_sent ? this.order.volumes_sent : this.order.volume
          return (
            moment(this.order.sending_date)
              .tz(this.user.timezone)
              .add(volume / this.order.sending_speed, 'hours')
              .format('HH.mm')
          )
        }
      } else {
        return moment(this.order.sending_end_date)
          .tz(this.user.timezone)
          .format('HH.mm')
      }
    },
    isEndingInNDays () {
      if (this.order.sending_end_date) {
        return moment(this.order.sending_end_date)
          .tz(this.user.timezone)
          .diff(new Date(), 'days')
      } else {
        return null
      }
    },
    isAlreadyStarted () {
      return moment(this.order.sending_date)
        .tz(this.user.timezone)
        .isBefore(new Date(), 'minute')
    }
  },
  methods: {
    formatDate (date) {
      return moment(date)
        .tz(this.user.timezone)
        .format('HH.mm')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_variables.scss';

::v-deep .el-progress-bar__innerText {
  display: none;
}
.current {
  height: 145px;
}
.el-card {
  background-color: $--color-white;
  border: 0.5px solid $--color-monitoring-grey;
  border-radius: 3px;
  width: 100%;
  margin-bottom: 15px;
  &:hover {
    cursor: pointer;
  }
}
::v-deep .el-card__body {
  padding: 10px;
}
.scheduled {
  height: 100px;
  background-color: $--color-scheduled-card;
  &-margin {
    margin-bottom: 0;
  }
}
.align {
  display: inline-block;
  & > * {
    vertical-align: middle;
  }
  &-padding {
    padding-bottom: 10px;
  }
}
.material-icons {
  color: $--color-info;
}
.diff-days {
  font-size: 16px;
  vertical-align: top;
}
.processing {
  color: $--color-primary;
  font-size: 18px;
}
.content {
  &-title {
    text-transform: capitalize;
  }
  font-size: 24px;
  font-weight: 400;
  &-error {
    font-size: 20px;
  }
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  &-margin {
    margin-bottom: 14px;
  }
  .icon {
    color: $--color-black;
  }
  .offline {
    color: $--color-monitoring-grey;
  }
  .icon-error, .status-error{
    color: $--color-danger;
  }
}
</style>
