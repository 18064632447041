<template>
  <div>
    <!-- Order details modal-->
    <el-dialog
      :visible="isOpen"
      :title="modalTitle"
      :show-close="state == 'detail'"
      :close-on-click-modal="state == 'detail'"
      :close-on-press-escape="state == 'detail'"
      :destroy-on-close="true"
      width="550px"
      class="order-details-modal"
      data-tested-as="orderDetailsConfirmationModal"
      @close="$emit('close-modal', false)"
    >
      <!-- STEP DETAIL: DEFAULT -->
      <OrderDetails
        v-if="state == 'detail'"
        key="detail"
        data-tested-as="ordersDetails"
        class="pt-2"
        :order="order"
        :is-monitoring="isMonitoring"
        @update-state="changeState"
        @notify-from-error="notifyFromError"
        @refresh-detail="refreshDetail"
      />

      <!-- STEP CANCEL -->
      <div
        v-else-if="state == 'cancel'"
        key="cancel"
        class="alert-modal-content"
      >
        <el-row class="pb-4 word-break">
          {{ $t("ORDER_ACTION_CANCEL_DESCRIPTION") }}
        </el-row>
        <el-row>
          <el-row style="text-align: right">
            <el-button
              type
              size="medium"
              @click="goBack"
            >
              {{ $t('CANCEL_BUTTON') }}
            </el-button>
            <el-button
              type="primary"
              size="medium"
              :loading="cancelling"
              @click="cancelCampaign"
            >
              {{ $t('CONFIRM_BUTTON') }}
            </el-button>
          </el-row>
        </el-row>
      </div>

      <!-- STEP RESEND -->
      <div
        v-if="state == 'resend'"
        key="resend"
        class="alert-modal-content"
      >
        <el-row class="pb-4">
          {{ $t("RESEND_CAMPAIGN_DESCRIPTION") }}

          <el-alert
            v-if="isPaused"
            :title="$t('RESEND_CAMPAIGN_PAUSE_WARNING', {
              firstname: pausedBy.first_name,
              lastname: pausedBy.last_name,
              date: pauseDate
            })"
            :closable="false"
            type="warning"
            class="mt-3"
            show-icon
          />

          <div class="pt-3">
            <el-checkbox v-model="checkErrorConfirm">
              {{ $t("RESEND_CAMPAIGN_CONFIRM" ) }}
            </el-checkbox>
          </div>
        </el-row>
        <el-row>
          <el-row style="text-align: right">
            <el-button
              type
              size="medium"
              :disabled="resending"
              @click="goBack"
            >
              {{ $t('CANCEL_BUTTON') }}
            </el-button>
            <el-button
              type="primary"
              size="medium"
              :disabled="!checkErrorConfirm"
              :loading="resending"
              @click="resendCampaign"
            >
              {{ $t('CONFIRM_BUTTON') }}
            </el-button>
          </el-row>
        </el-row>
      </div>

      <!-- STEP STOP (When the sending order is in pause) -->
      <div
        v-else-if="state == 'stop'"
        key="stop"
        class="alert-modal-content"
      >
        <el-row>
          <el-row class="pb-4 word-break">
            {{ $t("ORDER_ACTION_CANCEL_DESCRIPTION") }}
          </el-row>
          <el-row style="text-align: right">
            <el-button
              type
              size="medium"
              @click="goBack"
            >
              {{ $t('CANCEL_BUTTON') }}
            </el-button>
            <el-button
              type="primary"
              size="medium"
              :loading="stopping"
              @click="stopCampaign"
            >
              {{ $t('CONFIRM_BUTTON') }}
            </el-button>
          </el-row>
        </el-row>
      </div>

      <!-- STEP RESUME -->
      <div
        v-else-if="state == 'resume'"
        key="resume"
        class="alert-modal-content"
      >
        <el-row class="pb-4">
          {{ $t("ORDER_ACTION_RESUME_DESCRIPTION") }}
        </el-row>
        <el-row>
          <el-row style="text-align: right">
            <el-button
              type
              size="medium"
              @click="goBack"
            >
              {{ $t('CANCEL_BUTTON') }}
            </el-button>
            <el-button
              type="primary"
              size="medium"
              :loading="stopping"
              @click="resumeCampaign"
            >
              {{ $t('CONFIRM_BUTTON') }}
            </el-button>
          </el-row>
        </el-row>
      </div>

      <!-- STEP PAUSE -->
      <div
        v-else-if="state == 'pause'"
        key="pause"
        class="alert-modal-content"
      >
        <el-row class="pb-4">
          {{ $t("ORDER_ACTION_PAUSE_DESCRIPTION") }}
        </el-row>
        <el-row>
          <el-row style="text-align: right">
            <el-button
              type
              size="medium"
              @click="goBack"
            >
              {{ $t('CANCEL_BUTTON') }}
            </el-button>
            <el-button
              type="primary"
              size="medium"
              data-tested-as="orderDetailsConfirmPauseButton"
              :loading="stopping"
              @click="pauseCampaign"
            >
              {{ $t('CONFIRM_BUTTON') }}
            </el-button>
          </el-row>
        </el-row>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import OrderDetails from '@/components/Order/Details'
import config from '@/config'

export default {
  name: 'PartOrderDetails',
  components: { OrderDetails },
  props: {
    order: {
      required: true,
      validator: prop => typeof prop === 'object' || prop === null
    },
    state: {
      type: String,
      required: true
    },
    isLoading: { type: Boolean, default: false },
    isCancelled: { type: Boolean, default: false },
    isOpen: { type: Boolean, default: false },
    isMonitoring: { type: Boolean, default: false }
  },
  data () {
    return {
      directlyClose: true,
      transitionModalBack: false,
      refreshOrderDetailId: null,
      refreshCount: 0,
      checkErrorConfirm: false,
      cancelling: false,
      resending: false,
      stopping: false,
      title: {
        cancel: 'CANCEL_CAMPAIGN_TITLE',
        resend: 'RESEND_CAMPAIGN_TITLE',
        stop: 'CANCEL_CAMPAIGN_TITLE',
        pause: 'ORDER_ACTION_PAUSE_TITLE',
        resume: 'ORDER_ACTION_RESUME_TITLE'
      },
      notify: {
        unexpected: {
          title: this.$t('ERRORS.UNEXPECTED'),
          message: this.$t('ERRORS.UNEXPECTED_DESC'),
          type: 'error'
        },
        notFound: {
          title: this.$t('ERRORS.CAMPAIGNS_NOT_FOUND'),
          type: 'error'
        },
        conflict: {
          title: this.$t('ERRORS.ORDER_ACTION_CONFLICT'),
          type: 'error'
        }
      }
    }
  },
  computed: {
    modalTitle () {
      if (!this.title[this.state]) {
        return ''
      }
      return this.$t(this.title[this.state])
    },
    user () {
      return this.$store.getters['auth/user']
    },
    pauseDate () {
      if (!this.order.paused_at) {
        return ''
      }
      return moment(this.order.paused_at)
        .tz(this.user.timezone)
        .format('L [' + this.$t('AT') + '] LT')
    },
    pausedBy () {
      return this.order.paused_by || {}
    },
    isStarted () {
      return moment(this.order.sending_date) <= moment()
    }
  },
  watch: {
    isOpen () {
      // Opening on a specific state different than detail
      // we should close the modal if the user cancel
      this.directlyClose = this.state !== 'detail'
      setTimeout(
        () => {
          this.checkErrorConfirm = false
          this.refreshCount = 0
        }, 500
      )
    }
  },
  created () {
    this.refreshOrderDetailId = setInterval(() => {
      if (this.isOpen) {
        // When in error we want to refresh each tick ~ 15s
        // When sending is not completed or ignored we want to refresh each cycle (4 ticks)
        if (this.order.status_id === config.SENDING_ORDER_STATUS.ERROR && !this.order.ignore_date) {
          this.refreshDetail()
        } else if (this.refreshCount === 3 && !this.order.ignore_date && this.order.status_id !== config.SENDING_ORDER_STATUS.SENT) {
          this.refreshDetail()
        }
        this.refreshCount = this.refreshCount >= 3 ? 0 : this.refreshCount += 1
      }
    }, config.ORDER_POLLING_RATE)
  },
  beforeDestroy () {
    clearInterval(this.refreshOrderDetailId)
  },
  methods: {
    changeState (state) {
      this.transitionModalBack = state === 'detail'
      this.$emit('change-state', state)
    },
    notifyFromError (response) {
      this.cancelLoading()

      if (response.status === 409) {
        this.$notify(this.notify.conflict)
      } else if (response.status === 404) {
        this.$notify(this.notify.notFound)
        this.$emit('close-modal', false)
      } else {
        this.$notify(this.notify.unexpected)
      }
    },
    refreshDetail (refresh = true) {
      this.$store.dispatch('order/details', { orderId: this.order.id, refresh })
    },
    pauseCampaign () {
      this.stopping = true
      this.$store
        .dispatch('order/updateStatus', {
          orderId: this.order.id,
          status: config.ORDER_STATUS.PAUSED
        })
        .then(() => {
          this.refreshDetail(false)
          setTimeout(this.goBack, 500)
        })
        .catch(this.notifyFromError)
    },
    resumeCampaign () {
      this.stopping = true
      this.$store
        .dispatch('order/updateStatus', {
          orderId: this.order.id,
          status: config.ORDER_STATUS.STARTED
        })
        .then(() => {
          this.refreshDetail(false)
          setTimeout(this.goBack, 500)
        })
        .catch(this.notifyFromError)
    },
    stopCampaign () {
      this.stopping = true
      this.$store
        .dispatch('order/updateStatus', { orderId: this.order.id, cancel: true })
        .then(() => {
          this.refreshDetail(false)
          setTimeout(this.goBack, 500)
        })
        .catch(this.notifyFromError)
    },
    cancelCampaign () {
      this.cancelling = true
      this.$store
        .dispatch('order/ignore', { orderId: this.order.id })
        .then(() => {
          this.refreshDetail(false)
          setTimeout(this.goBack, 500)
        })
        .catch(this.notifyFromError)
    },
    resendCampaign () {
      this.resending = true
      this.$store
        .dispatch('order/resend', { orderId: this.order.id })
        .then(() => {
          this.refreshDetail(false)
          setTimeout(this.goBack, 500)
        })
        .catch(this.notifyFromError)
    },
    cancelLoading () {
      this.resending = false
      this.cancelling = false
      this.stopping = false
    },
    resetState () {
      this.transitionModalBack = true
      this.checkErrorConfirm = false
      this.changeState('detail')
      this.cancelLoading()
    },
    goBack () {
      if (this.directlyClose) {
        this.$emit('close-modal', false)
        // Delay the reset to avoid noise in the closing animation
        setTimeout(this.resetState, 500)
      } else {
        this.resetState()
      }
    },
  }
}
</script>

<style lang="scss">
@import "@/assets/styles/_variables.scss";
.div-slider {
  position: relative;
  display: flex;
  flex-flow: row;
  overflow: hidden;

  > div {
    flex: none;
    width: 100%;
    box-sizing: border-box;
  }
}

.order-details-modal .el-dialog__body {
  padding: 0px !important;
}

.alert-modal-content {
  padding: 20px;
}

.order-details-modal .el-dialog__title {
  color: $--color-primary;
}

.cancel-modal {
  .el-dialog__title {
    color: $--color-danger;
  }
}

.order-details-modal-body {
  text-align: center;
  width: 90%;
  margin: auto;
}

.el-alert--warning {
  word-break: normal;
}

.word-break {
  word-break: normal;
}

</style>
